import { getPreferencesWithoutPages, SearchDictInterface } from '@/shared/components/searchEngine';
import { RootState } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocalPreferences } from '@/types/search';
import { IOrderAppearanceStatuses } from '@/types/order';

export const defaultPreferences: LocalPreferences = {
  search: {},
  showOrders: IOrderAppearanceStatuses.Visible,
  showSetPasswordNotification: true,
};

const localPreferenceSlice = createSlice({
  name: 'localPreferences',
  initialState: defaultPreferences,
  reducers: {
    setSearchPreferences: (state: LocalPreferences, { payload }: PayloadAction<SearchDictInterface>) => {
      state.search = payload;
    },
    resetSearchPagination: (state: LocalPreferences) => getPreferencesWithoutPages(state),
    setLocalPreferences(state: LocalPreferences, { payload }: PayloadAction<LocalPreferences>) {
      return payload;
    },
    setShowOrdersPreferences(state: LocalPreferences, { payload }: PayloadAction<IOrderAppearanceStatuses>) {
      state.showOrders = payload;
    },
    setShowSetPasswordNotification(state: LocalPreferences, { payload }: PayloadAction<boolean>) {
      state.showSetPasswordNotification = payload;
    },
    resetLocalPreferences(state: LocalPreferences) {
      state.showSetPasswordNotification = true;
    },
  },
});

export const {
  setSearchPreferences,
  resetLocalPreferences,
  setLocalPreferences,
  resetSearchPagination,
  setShowOrdersPreferences,
  setShowSetPasswordNotification,
} = localPreferenceSlice.actions;

export const searchPreferencesSelector = ({ localPreferences }: RootState): SearchDictInterface | undefined => localPreferences.search;

export const showOrderPreferencesSelector = ({ localPreferences }: RootState): IOrderAppearanceStatuses =>
  localPreferences.showOrders || IOrderAppearanceStatuses.Visible;

export const showSetPasswordNotificationSelector = ({ localPreferences }: RootState): boolean =>
  localPreferences.showSetPasswordNotification;

export default localPreferenceSlice.reducer;
